import { NotificationModel } from '@/app/shared/interfaces/notification.interface';
import { PushNotificationService } from '@/app/shared/services/push-notification.service';
import { SharedService } from '@/app/shared/services/shared.service';
import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
// import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
// import { Notification } from '../../../../../shared/interface/notification.interface';
// import { NavService } from '../../../../../shared/services/nav.service';
// import { NotificationState } from '../../../../../shared/state/notification.state';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  
  public unreadNotificationCount: number | undefined;
  public notifications: NotificationModel[] = [];
  public active: boolean = false;
  private subscibe: Subscription | undefined;
  private subscibeChange: Subscription | undefined;
  private formatDateValue = 'dd/MM/yyyy HH:mm:ss';

  constructor( private sharedServices: SharedService,
    private pushNotiService: PushNotificationService,
    private sharedService: SharedService,
    private router: Router,
   ) {
    this.subscibe = this.sharedServices.newNotification$.subscribe(() => {
      this.pushNotiService.getNotifications().subscribe({
        next: (res: NotificationModel[]) => {
          this.unreadNotificationCount = res.filter(item => !item.isRead).length;
          this.notifications = res;
        },
        error: (err: any) => {
          console.error(err);
        }
      });
    });
    this.subscibeChange = this.sharedServices.notificationChange$.subscribe(() => {
      this.pushNotiService.getNotifications().subscribe({
        next: (res: NotificationModel[]) => {
          this.unreadNotificationCount = res.filter(item => !item.isRead).length;
        },
        error: (err: any) => {
          console.error(err);
        }
      });
    })
  }

  clickHeaderOnMobile(){
    this.active= !this.active
  }
  dateTimeFormat(date: string) {
    if (date) {
      return formatDate(date, this.formatDateValue, 'vi-VN');
    }
    else {
      return "-";
    }
  }
  viewNotificationDetail(noti: NotificationModel) {
    this.pushNotiService.readNotification(noti).subscribe({
      next: (res: boolean) => {
        this.sharedService.notificationChange();
      }
    });

    if (NotificationModel.getNotiTargetUrl(noti)) {
      this.router.navigateByUrl(NotificationModel.getNotiTargetUrl(noti));
    }
  }
  ngOnDestroy(){
    this.subscibe?.unsubscribe();
    this.subscibeChange?.unsubscribe();
  }
}
