<li class="profile-nav onhover-dropdown pe-0 me-0">
    <div class="media profile-media" (click)="clickHeaderOnMobile()">
        <div class="profile-img">
            <img class="user-profile rounded-circle" 
                [src]="user.profile_image?.original_url"
                alt="profile" 
                *ngIf="user.profile_image else placeholder">
        </div>
        <ng-template #placeholder>
            <div class="user-round">
                <h4>{{ user.username.charAt(0)?.toString()?.toUpperCase() }}</h4>
            </div>
        </ng-template>
        <div class="user-name-hide media-body">
            <span>{{ user.username }}</span>
            <p class="mb-0 font-roboto">{{ user.role }}
                <i class="middle ri-arrow-down-s-line"></i>
            </p>
        </div>
    </div>
    <ul class="profile-dropdown onhover-show-div" [class.active]="active">
        <li>
            <a [routerLink]="['/notification/manage']">
                <i class="ri-user-line"></i>
                <span>{{ 'Quản lý thông báo' | translate }}</span>
            </a>
        </li>
        <li>
            <a (click)="confirmationModal.openModal('')">
                <i class="ri-logout-box-line"></i>
                <span>{{ 'log_out' | translate }}</span>
            </a>
        </li>
    </ul>
</li>

<app-confirmation-modal 
    #confirmationModal
    [title]="'Xác nhận'"
    [message]="'Bạn chắc chắn muốn đăng xuất khỏi hệ thống ?'"
    (confirmed)="logout($event)">
</app-confirmation-modal>