import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductModel } from '../interfaces/product.interface';
import { Wheel } from '../interfaces/minigame.interface';

@Injectable({
  providedIn: 'root',
})
export class MinigameService {
  constructor(private http: HttpClient) {}

  checkMinigameWheelFeature() {
    return this.http.get<boolean>(`${environment.baseURL}check-available-feature/wheel`);
  }

  getAllMinigame() {
    return this.http.get<any>(`${environment.baseURL}wheel/get-all-wheel`);
  }

  getMinigameById(id: string) {
    return this.http.get<any>(`${environment.baseURL}wheel/${id}`);
  }

  createMinigame(body: Wheel) {
    return this.http.post(`${environment.baseURL}wheel`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  updateMinigame(body: Wheel) {
    return this.http.put(`${environment.baseURL}wheel`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  //get with body
  getWheelHistoryList(body: string) {
    return this.http.post(
      `${environment.baseURL}wheel/admin/spin-history`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  setSpinAmount(body: string) {
    return this.http.post(
      `${environment.baseURL}wheel/admin/set-spin-amount`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  getSpinRemaining(userName: string) {
    return this.http.get<any>(
      `${environment.baseURL}wheel/admin/spin-remaining/${userName}`
    );
  }

  deleteMinigame(id: string) {
    return this.http.delete(`${environment.baseURL}wheel/${id}`);
  }
}
