import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Configuration, ConfigurationAction, Menu } from "@/app/shared/interfaces/configuration.interface";
import { Observable } from "rxjs";
import { BaseResponseModel } from "../interfaces/base-response.interface";
import { SubscribeListModel } from "../interfaces/notification.interface";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {

  constructor(private http: HttpClient) {}

  getConfiguration(userName?: string) {
    // return this.http.get<Configuration>(`${environment.baseURL}configuration`);
    if (userName)
      return this.http.get<Configuration>(`${environment.baseURL}configuration/${userName}`);
    else
      return this.http.get<Configuration>(`${environment.baseURL}configuration`);
  }
  
  upsertConfigurationForUser(model: string, action: ConfigurationAction) {
    return this.http.post(`${environment.baseURL}configuration?action=${action}`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  getSideBar() {
    return this.http.get<Menu[]>(`${environment.baseURL}admin-sidebar`);
  }
  getHomeConfiguration() {
    return this.http.get(`${environment.baseURL}home-config`);
  }
  getHomeBanner() {
    return this.http.get(`${environment.baseURL}home-banner`);
  }
  
  getVendorBanner() {
    return this.http.get(`${environment.baseURL}vendor-banner`);
  }

  getCommentBanner() {
    return this.http.get(`${environment.baseURL}comment-banner`);
  }

  getPopupBanner() {
    return this.http.get(`${environment.baseURL}popup-banner`);
  }
  getFlashsaleInfo() {
    return this.http.get(`${environment.baseURL}flashsale-info`);
  }

  getGiftInfo() {
    return this.http.get<Menu[]>(`${environment.baseURL}gift-info`);
  }

  updateHomeConfiguration(model: any) {
    return this.http.post(`${environment.baseURL}home-config`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateHomeBanner(model: any) {
    return this.http.post(`${environment.baseURL}home-banner`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateVendorBanner(model: any) {
    return this.http.post(`${environment.baseURL}vendor-banner`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateCommentBanner(model: any) {
    return this.http.post(`${environment.baseURL}comment-banner`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updatePopupBanner(model: any) {
    return this.http.post(`${environment.baseURL}popup-banner`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateFlashsaleInfo(model: any) {
    return this.http.post(`${environment.baseURL}flashsale-info`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateGiftInfo(model: any) {
    return this.http.post(`${environment.baseURL}gift-info`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  subscribeToken(token: string) : Observable<BaseResponseModel> {
    let model = {
      'token': token
    };
    return this.http.post<BaseResponseModel>(`${environment.baseURL}firebasesubscribe/v1/subscribe`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  getSubscribeList() : Observable<SubscribeListModel[]> {
    return this.http.get<SubscribeListModel[]>(`${environment.baseURL}firebasesubscribe/v1/subscribe-list`, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  updateSubscribeList(model: SubscribeListModel[]) : Observable<BaseResponseModel> {
    return this.http.post<BaseResponseModel>(`${environment.baseURL}firebasesubscribe/v1/subscribe-list`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
}
