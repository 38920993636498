<div class="onhover-dropdown" (click)="clickHeaderOnMobile()">
    <div class="notification-box">
        <div>
            <i class="ri-notification-line"></i>
        </div>
        <span class="badge rounded-pill badge-theme" *ngIf="unreadNotificationCount">
            {{ unreadNotificationCount }}
        </span>
    </div>
    <ul class="notification-dropdown onhover-show-div" [class.active]="active" *ngIf="notifications.length else noNotifies">
        <li>
            <i class="ri-notification-line"></i>
            <h6 class="f-18 mb-0">{{ 'notifications' | translate }}</h6>
        </li>
        <li *ngFor="let n of notifications | slice:0:3" class="border-bottom" (click)="viewNotificationDetail(n)">
            <p>
                {{n.title | summary:30}} 
            </p>
            <div>
                <span class="text-muted" style="font-size: small;">{{dateTimeFormat(n.createdAt)}}</span>
            </div>
            <p>
                <i class="ri-record-circle-line me-2 txt-primary"></i> {{ n.body | summary:30 }}
            </p>
        </li>
        <li>
            <a [routerLink]="['/notification']" class="btn btn-theme">
                {{ 'check_all_notification' | translate }}
            </a>
        </li>
    </ul>
    <ng-template #noNotifies>
        <ul class="notification-dropdown onhover-show-div">
            <li>
                <i class="ri-notification-line"></i>
                <h6 class="f-18 mb-0">{{ 'notifications' | translate }}</h6>
            </li>
            <li>{{'Không có thông báo mới' | translate}}</li>
        </ul>
    </ng-template>
</div>