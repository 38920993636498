import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NotificationModel } from "../interfaces/notification.interface";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class PushNotificationService {
    constructor(private http: HttpClient) { }

    private localStorageKey = "push-notification";

    saveNotification(noti: NotificationModel): Observable<any> {
        let s = localStorage.getItem(this.localStorageKey);
        let data: NotificationModel[] = [];
        if (s) {
            data = JSON.parse(s);
        }
        data.unshift(noti);
        localStorage.setItem(this.localStorageKey, JSON.stringify(data));
        return new BehaviorSubject(1);
    }

    getNotifications(): Observable<NotificationModel[]> {
        let s = localStorage.getItem(this.localStorageKey);
        let data: NotificationModel[] = [];
        if (s) {
            data = JSON.parse(s);
        }
        return new BehaviorSubject(data);
    }

    readNotification(noti: NotificationModel): Observable<boolean> {
        let s = localStorage.getItem(this.localStorageKey);
        let data: NotificationModel[] = [];
        if (s) {
            data = JSON.parse(s);
        }
        let existed = data.find(p => p.id == noti.id);
        if (existed) {
            existed.isRead = true;
            localStorage.setItem(this.localStorageKey, JSON.stringify(data));
        }

        return new BehaviorSubject(true);
    }

}