export class SubscribeListModel {
    topicName: string;
    isActived: boolean;
}

export class NotificationModel {
    id: string;
    isRead: boolean = false;
    title: string;
    body: string;
    createdAt: string;
    dataTypeName: string;
    data: any;

    constructor(init?: Partial<NotificationModel>){
        Object.assign(this, init);
    }

    static getNotiTargetUrl(noti: NotificationModel): string {
        if (noti.dataTypeName === 'Order') {
            return 'order/details/' + encodeURIComponent(noti.data.Id);
        } else if (noti.dataTypeName === 'User') {
            return 'user/edit' + encodeURIComponent(noti.data.UserName);
        }
        return '';
    }
}